import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
import PageHeader from "../../../../../components/PageHeader";
import * as studentService from "../../../services/AssignmentStudentService";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../../../../../components/mui/Notification";
import { MuiButton } from "../../../../../components";
import { CASE_STUDY_ASSIGNMENT_STUDENT_ANSWER } from "../../../helper/Constants";

export default function List() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const assignment = state.assignment;
  const assignmentId = assignment.id;
  const casestudyId = state.casestudyId;

  const handleRowClick = (params) => {
    const groupId = params.row.groupId;
    if (groupId) {
      navigate(CASE_STUDY_ASSIGNMENT_STUDENT_ANSWER, {
        state: { casestudyId, assignmentId, groupId },
      });
    }
  };

  const Status = ({ groupId }) => {
    // const navigate = useNavigate();

    // Handler for navigation
    // const handleNavigate = () => {
    //   if (groupId) {
    //     navigate(`/answers/${groupId}`);
    //   }
    // };

    return groupId ? (
      // "Submitted"
      // <Link href="#" underline="none">
      //   {'Submitted'}
      // </Link>
      <MuiButton
        size="small"
        text="Answer Submitted"
        variant="text"
        onClick={() => console.log("Done")}
      />
    ) : (
      "Not submitted"
    );
  };

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1, minWidth: 100 },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "groupId",
      headerName: "Status",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => <Status groupId={params.value} />,
    },
  ];

  useEffect(() => {
    studentService
      .getAll(assignmentId, casestudyId)
      .then((response) => {
        setStudents(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title="List of Students"
        isChild={true}
        onRefresh={() => onRefresh()}
      />
      <DataGrid
        loading={loading}
        autoHeight
        // checkboxSelection
        rows={students}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        // initialState={{
        //   pagination: { paginationModel: { pageSize: 20 } },
        // }}
        // pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        onRowClick={handleRowClick}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                variant: "outlined",
                size: "small",
              },
              columnInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              operatorInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
            },
          },
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
