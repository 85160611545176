import React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reducer from "./reducers/reducer";
import initialState from "./reducers/initials";
import ResponsiveLayout from "./components/ResponsiveLayout";
import {
  CaseStudyAssignments,
  NewCaseStudyAssignment,
} from "./instructor/assignment";
import {
  NewCaseStudy,
  InstructorCaseStudyList,
  InstructorCaseStudyView,
  CaseStudyPurchaseByState,
} from "./instructor/casestudy";
import {
  InstructorCourseList,
  InstructorCourseUpsert,
} from "./instructor/course";
import {
  AccountVerified,
  ForgotPassword,
  Login,
  ResetPassword,
  Signup,
  UserProfile,
} from "./user";
import Landing from "./landing/LandingPage";
import {
  ACCOUNT_ACTIVATED_ROUTE,
  USER_INSTITUTION_ACCOUNT_VERIFIED_ROUTE,
  FORGOT_PASS_ROUTE,
  INSTRUCTOR_BOARD_ROUTE,
  INSTRUCTOR_INSTITUTION_UPSERT_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  RESET_PASS_ROUTE,
  SINGUP_ROUTE,
  INSTRUCTOR_COURSE_LIST_ROUTE,
  CASE_STUDY_PURCHASE_ROUTE,
  CASE_STUDY_PURCHASE_COMPLETION_ROUTE,
  CASE_STUDY_PURCHASE_CHECKOUT_ROUTE,
  CASE_STUDY_ASSIGNMENTS,
  CASE_STUDY_LIST,
  CASE_STUDY_ASSIGNMENT_GROUPS,
  CASE_STUDY_NEW,
  CASE_STUDY_ASSIGNMENT_NEW,
  CASE_STUDY_ASSIGNMENT_STUDENTS,
  CASE_STUDY_ASSIGNMENT_STUDENT_ANSWER,
} from "./helper/Constants";
import { InstructorBoard } from "./instructor/board";
import {
  InstitutionAccountVerified,
  NewInstructorInstitution,
} from "./instructor/institution";
import { CaseStudyCheckoutPage, CaseStudyPurchaseCompleted } from "./stripe";
import NotFound from "../../pages/common/NotFound";
import { CaseStudyAssignmentGroups } from "./instructor/assignment/group";
import {
  CaseStudyAssignmentAnswer,
  CaseStudyAssignmentStudents,
} from "./instructor/assignment/student";

export const AppContext = React.createContext();

let theme = createTheme({
  palette: {
    primary: {
      main: "#0B72B9",
      light: "#3c44b126",
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  const [appState, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider
      value={{
        appState,
        dispatch,
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ResponsiveLayout>
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route
                exact
                path={INSTRUCTOR_BOARD_ROUTE}
                element={<InstructorBoard />}
              />
              <Route exact path={LOGIN_ROUTE} element={<Login />} />
              <Route exact path={SINGUP_ROUTE} element={<Signup />} />
              <Route
                exact
                path={ACCOUNT_ACTIVATED_ROUTE}
                element={<AccountVerified />}
              />
              <Route
                exact
                path={USER_INSTITUTION_ACCOUNT_VERIFIED_ROUTE}
                element={<InstitutionAccountVerified />}
              />
              <Route exact path={PROFILE_ROUTE} element={<UserProfile />} />
              <Route
                exact
                path={FORGOT_PASS_ROUTE}
                element={<ForgotPassword />}
              />
              <Route
                exact
                path={RESET_PASS_ROUTE}
                element={<ResetPassword />}
              />
              <Route
                exact
                path={INSTRUCTOR_COURSE_LIST_ROUTE}
                element={<InstructorCourseList />}
              />
              <Route
                exact
                path={CASE_STUDY_LIST}
                element={<InstructorCaseStudyList />}
              />

              <Route
                exact
                path={CASE_STUDY_ASSIGNMENT_NEW}
                element={<NewCaseStudyAssignment />}
              />
              <Route
                exact
                path={CASE_STUDY_ASSIGNMENTS}
                element={<CaseStudyAssignments />}
              />
              <Route
                exact
                path={CASE_STUDY_ASSIGNMENT_GROUPS}
                element={<CaseStudyAssignmentGroups />}
              />
              <Route
                exact
                path={CASE_STUDY_ASSIGNMENT_STUDENTS}
                element={<CaseStudyAssignmentStudents />}
              />
              <Route
                exact
                path={CASE_STUDY_ASSIGNMENT_STUDENT_ANSWER}
                element={<CaseStudyAssignmentAnswer />}
              />
              <Route
                exact
                path="/casestudy/view"
                element={<InstructorCaseStudyView />}
              />
              <Route exact path={CASE_STUDY_NEW} element={<NewCaseStudy />} />
              <Route
                exact
                path="/course/upsert"
                element={<InstructorCourseUpsert />}
              />
              <Route
                exact
                path={INSTRUCTOR_INSTITUTION_UPSERT_ROUTE}
                element={<NewInstructorInstitution />}
              />
              <Route
                exact
                path={CASE_STUDY_PURCHASE_ROUTE}
                element={<CaseStudyPurchaseByState />}
              />
              <Route
                exact
                path={CASE_STUDY_PURCHASE_CHECKOUT_ROUTE}
                element={<CaseStudyCheckoutPage />}
              />
              <Route
                exact
                path={CASE_STUDY_PURCHASE_COMPLETION_ROUTE}
                element={<CaseStudyPurchaseCompleted />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ResponsiveLayout>
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
