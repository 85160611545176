import { httpClient } from "./RestService";

export const getAll = async (assignmentId, casestudyId) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/" + assignmentId + "/list/" + casestudyId
  );
};

export const getAnswers = async (groupId) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/answer/" + groupId
  );
};

export const getCriteria = async (evalId) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/criteria/" + evalId
  );
};

export const updateGrade = async (evalId, body) => {
  return await httpClient().put(
    "/v1/education/cs/assmnt/student/eval/" + evalId,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const approve = async (evalId) => {
  return await httpClient().put(
    "/v1/education/cs/assmnt/student/eval/approve/" + evalId
  );
};

export const reEvaluate = async (request) => {
  return await httpClient().post(
    "/v1/education/cs/assmnt/student/eval/retry",
    request
  );
};
